import { Website_Info } from '~/website.models'

export function getBranding(websiteInfo: Website_Info | undefined) {
  if (!websiteInfo) {
    return {
      highlightColor: '',
      primaryColor: '',
      secondaryColor: '',
      tertiaryColor: '',
      logoUrl: '',
    }
  }

  const locationInfo = websiteInfo?.location_info?.[0] ?? {}
  const companyInfo = locationInfo?.company?.company_info ?? {}
  const overrideCompanyBrand = locationInfo?.override_company_brand ?? false

  if (overrideCompanyBrand) {
    return {
      highlightColor: locationInfo?.highlight_color ?? '',
      primaryColor: locationInfo?.primary_color ?? '',
      secondaryColor: locationInfo?.secondary_color ?? '',
      tertiaryColor: locationInfo?.tertiary_color ?? '',
      logoUrl: locationInfo?.logo_url ?? '',
    }
  }

  return {
    highlightColor: companyInfo?.highlight_color ?? '',
    primaryColor: companyInfo?.primary_color ?? '',
    secondaryColor: companyInfo?.secondary_color ?? '',
    tertiaryColor: companyInfo?.tertiary_color ?? '',
    logoUrl: companyInfo?.logo_url ?? '',
  }
}
