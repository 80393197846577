import { Link, useRouteLoaderData } from '@remix-run/react'
import xss from 'xss'

import { Website_Info } from '../website.models'

export function Footer() {
  const data = useRouteLoaderData<Website_Info>('root')

  const settings = data?.config?.[0]
  const locationHtml =
    data?.blocks?.find(block => block.type === 'location')?.html ?? ''

  const hoursHtml =
    data?.blocks?.find(block => block.type === 'hours')?.html ?? ''

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-col location">
            <h3>Location</h3>

            <span
              dangerouslySetInnerHTML={{ __html: xss(locationHtml) }}
            ></span>
          </div>

          {settings
            ? (settings.newsletter_api_key ||
                settings.facebook_handle ||
                settings.twitter_handle ||
                settings.instagram_handle) && (
                <div className="footer-col">
                  {settings.newsletter_api_key && (
                    <div id="mc_embed_shell">
                      <div id="mc_embed_signup">
                        <form
                          action={settings.newsletter_api_key}
                          method="post"
                          id="mc-embedded-subscribe-form"
                          name="mc-embedded-subscribe-form"
                          className="validate"
                          target="_self"
                        >
                          <div id="mc_embed_signup_scroll">
                            <h3>Newsletter Signup</h3>
                            <div className="signup">
                              <input
                                type="email"
                                name="EMAIL"
                                className="signup-input required email"
                                id="mce-EMAIL"
                                placeholder="Email"
                                required
                                autoComplete="false"
                              />
                              <span
                                id="mce-EMAIL-HELPERTEXT"
                                className="helper_text"
                              ></span>

                              <button
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="btn small plr-small"
                                value="Go"
                              >
                                Go
                              </button>
                            </div>
                            <div id="mce-responses" className="clear foot">
                              <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: 'none', margin: '5px 0 0 0' }}
                              ></div>

                              <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: 'none' }}
                              ></div>
                            </div>
                            <div
                              aria-hidden="true"
                              style={{ position: 'absolute', left: '-5000px' }}
                            >
                              <input
                                type="text"
                                name="b_a8cb5677b35af38f95b5d120c_4424d32012"
                                tabIndex={-1}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {(settings.facebook_handle ||
                    settings.twitter_handle ||
                    settings.instagram_handle) && (
                    <ul className="social-icons">
                      {settings.facebook_handle && (
                        <li>
                          <a
                            href={`http://facebook.com/${settings.facebook_handle}`}
                            target="_blank"
                            rel="noreferrer"
                            title="facebook"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 50 50"
                            >
                              <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" />
                            </svg>
                          </a>
                        </li>
                      )}

                      {settings.twitter_handle && (
                        <li>
                          <a
                            href={`http://twitter.com/${settings.twitter_handle}`}
                            target="_blank"
                            rel="noreferrer"
                            title="twitter"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 50 50"
                            >
                              <path d="M 50.0625 10.4375 C 48.214844 11.257813 46.234375 11.808594 44.152344 12.058594 C 46.277344 10.785156 47.910156 8.769531 48.675781 6.371094 C 46.691406 7.546875 44.484375 8.402344 42.144531 8.863281 C 40.269531 6.863281 37.597656 5.617188 34.640625 5.617188 C 28.960938 5.617188 24.355469 10.21875 24.355469 15.898438 C 24.355469 16.703125 24.449219 17.488281 24.625 18.242188 C 16.078125 17.8125 8.503906 13.71875 3.429688 7.496094 C 2.542969 9.019531 2.039063 10.785156 2.039063 12.667969 C 2.039063 16.234375 3.851563 19.382813 6.613281 21.230469 C 4.925781 21.175781 3.339844 20.710938 1.953125 19.941406 C 1.953125 19.984375 1.953125 20.027344 1.953125 20.070313 C 1.953125 25.054688 5.5 29.207031 10.199219 30.15625 C 9.339844 30.390625 8.429688 30.515625 7.492188 30.515625 C 6.828125 30.515625 6.183594 30.453125 5.554688 30.328125 C 6.867188 34.410156 10.664063 37.390625 15.160156 37.472656 C 11.644531 40.230469 7.210938 41.871094 2.390625 41.871094 C 1.558594 41.871094 0.742188 41.824219 -0.0585938 41.726563 C 4.488281 44.648438 9.894531 46.347656 15.703125 46.347656 C 34.617188 46.347656 44.960938 30.679688 44.960938 17.09375 C 44.960938 16.648438 44.949219 16.199219 44.933594 15.761719 C 46.941406 14.3125 48.683594 12.5 50.0625 10.4375 Z" />
                            </svg>
                          </a>
                        </li>
                      )}

                      {settings.instagram_handle && (
                        <li>
                          <a
                            href={`http://instagram.com/${settings.instagram_handle}`}
                            target="_blank"
                            rel="noreferrer"
                            title="instagram"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 50 50"
                            >
                              <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              )
            : null}

          <div className="footer-col hours">
            <h3>Hours</h3>

            <span dangerouslySetInnerHTML={{ __html: xss(hoursHtml) }}></span>
          </div>
        </div>
      </footer>

      <div className="copyright">
        &copy; {new Date().getFullYear()} {settings?.default_title ?? ''}
        <span> &nbsp; | &nbsp; &nbsp;</span>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </>
  )
}
