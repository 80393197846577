import { Link } from '@remix-run/react'
import classNames from 'classnames'

export function Logo({ size, url }: { size: string; url: string }) {
  return (
    <div className={classNames(`logo ${size}`)}>
      <Link to="/">
        <img src={url} alt="logo" />
      </Link>
    </div>
  )
}
