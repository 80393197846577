import { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRevalidator,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import slugify from '@sindresorhus/slugify'
import { useEffect } from 'react'

import { Footer } from './components/footer'
import { Header } from './components/header'
import { getBranding } from './utils/get-branding'
import { WebsiteInfoLoader } from './utils/website-info.loader'
import { Website_Info } from './website.models'

import '~/styles/root.scss'

export const loader = async ({ request }: LoaderFunctionArgs) =>
  WebsiteInfoLoader({ request })

export const meta: MetaFunction<typeof loader> = ({
  data,
  params,
  location,
}) => {
  if (!data) {
    return []
  }

  const url = `${data?.origin}${location.pathname === '/' ? '' : location.pathname}`

  const pageParam = params.page ?? ''

  const page =
    data.pages?.find(
      (page: { title: string }) => slugify(page.title) === (pageParam ?? ''),
    ) || data.pages?.find((page: { type: string }) => page.type === 'home')

  const { config } = data
  const settings = config?.[0] ?? {}
  const { twitter_handle } = settings

  const title = (page?.meta_title || settings?.default_meta_title) ?? ''
  const description =
    (page?.meta_description || settings?.default_meta_description) ?? ''

  const { logoUrl } = getBranding(data)

  return [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: logoUrl,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      property: 'og:site_name',
      content: title,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: logoUrl,
    },
    {
      name: 'twitter:creator',
      content: twitter_handle,
    },
    {
      name: 'twitter:site',
      content: twitter_handle,
    },
    {
      title,
    },
  ]
}

function Document({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const data = useRouteLoaderData<Website_Info>('root')

  const { revalidate } = useRevalidator()

  useEffect(() => {
    const id = setInterval(revalidate, 1000 * 60 * 10) // refresh data every to 10 minutes

    return () => clearInterval(id)
  }, [revalidate])

  useEffect(() => {
    const polyfillScript = document.createElement('script')
    polyfillScript.src = `https://files${data?.env === 'prod' ? '' : '.dev'}.bevwerk.com/polyfills.js`
    polyfillScript.type = 'module'
    polyfillScript.defer = true
    document.body.appendChild(polyfillScript)

    const menuScript = document.createElement('script')
    menuScript.src = `https://files${data?.env === 'prod' ? '' : '.dev'}.bevwerk.com/website-menu.js`
    menuScript.type = 'module'
    menuScript.defer = true
    document.body.appendChild(menuScript)

    return () => {
      if (menuScript) {
        document.body.removeChild(menuScript)
      }

      if (polyfillScript) {
        document.body.removeChild(polyfillScript)
      }
    }
  })

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = data?.faviconUrl ?? link.href
  }, [data?.faviconUrl])

  return (
    <Document>
      {data?.location_id ? (
        <link rel="stylesheet" type="text/css" href={data.stylesheetUrl} />
      ) : null}

      <Header />

      <Outlet context={{ ...data }} />

      <Footer />
    </Document>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const data = useRouteLoaderData<Website_Info>('root')

  const error = useRouteError()

  console.error(error)

  captureRemixErrorBoundaryError(error)

  let errorMessage = ''

  if (isRouteErrorResponse(error)) {
    errorMessage = `${error.status} ${error.statusText}`
  } else {
    errorMessage = error instanceof Error ? error.message : 'Unknown error'
  }

  return (
    <Document title={errorMessage}>
      {data ? (
        <>
          <Header />
          <div className="page-content">
            <div className="container">
              <h1>{errorMessage}</h1>

              <p>
                Ladies and gentlemen, let me tell you, we have the best
                websites, the best servers, tremendous, believe me. But
                sometimes, even with all our greatness, you hit a 404 error.
                Now, I&apos;ll tell you, it&apos;s not our fault, okay?
                It&apos;s those nasty little gremlins, probably Democrats,
                trying to sabotage us. But don&apos;t worry, we&apos;re gonna
                fix it, and we&apos;re gonna make the internet great again! So
                just hang tight, we&apos;ll have this all sorted out in no time.
                Thank you, thank you very much!
              </p>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container">
              <h1>{errorMessage}</h1>
            </div>
          </div>
        </>
      )}
    </Document>
  )
}
